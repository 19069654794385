<template>
  <div class="login">
    <div class="login-header">
      <img src="@/assets/login/icon-login.png" />
      <div>比特丛林</div>
    </div>
    <div class="login-form">
      <div class="input-wrapper">
        <input
          v-model="form.loginInfo"
          ref="username"
          type="text"
          @focus="inputfocus"
          placeholder="请输入邮箱"
        />
        <!-- <label for="inputID">请输入...</label> -->
      </div>
      <div class="input-wrapper">
        <input
          v-model="form.registerPwd"
          type="password"
          @focus="inputfocus"
          placeholder="请输入密码"
        />
        <!-- <label for="inputID">请输入...</label> -->
      </div>
      <div class="input-wrapper">
        <input
          v-model="form.registerPwdConfirm"
          type="password"
          @focus="inputfocus"
          placeholder="确认密码"
        />
        <!-- <label for="inputID">请输入...</label> -->
      </div>
      <div class="send-code">
        <div class="input-wrapper" style="width: 280px">
          <input
            v-model="form.code"
            type="password"
            @focus="inputfocus"
            placeholder="请输入邮箱验证码"
            
          />
          <!-- <label for="inputID">请输入...</label> -->
        </div>
        <el-button @click="sendCode" style="width: 100px;height:40px;margin-left:20px;">发送验证码</el-button>
      </div>
      <el-button type="primary" @click="register">注册</el-button>
    </div>
    <div class="login-other">
      <div>其它登录方式</div>
      <div class="login-other-way">
        <img src="@/assets/login/icon-wx.png" />
        <img src="@/assets/login/icon-wf.png" />
      </div>
    </div>
    <div class="login-register">没有账号？<span style="color: #1a73e9">立即注册</span></div>
  </div>
</template>

<script>
import { getKey, registerFirst, userCheck, register, getUserDetail } from "@/api/login";
import CryptoJS from "crypto-js";
export default {
  name: "login",
  data() {
    return {
      form: {
        loginInfo: "",
        registerPwd: "",
      },
    };
  },
  mounted() {
    this.getKey();
  },
  methods: {
    sendCode() {
      userCheck(this.form.loginInfo)
        .then((res) => {
          if (res.data.code != "0") {
            this.$message.error(res.data.message);
            return;
          }
          return getKey();
        })
        .then((res) => {
          console.log(res);
          let logindata = res.data.data;
          //调用登录
          return registerFirst(
            this.form.loginInfo,
            this.pwdAes(this.form.registerPwd, logindata.tokenKey),
            logindata.userKey
          );
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.$message.success("发送成功");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    pwdAes(pwd, tokenKey) {
      let key = tokenKey;
      let iv = tokenKey;

      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);

      let srcs = CryptoJS.enc.Utf8.parse(pwd);

      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      //返回base64
      return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },
    register() {
      register(this.form.loginInfo, this.form.code)
        .then((res) => {
          if (res.data.code == "0") {
            this.$message.success("注册成功");

            //保存用户信息
            localStorage.setItem("accessToken", res.data.token);
            let userId = res.data.data.id;
            return getUserDetail(userId);
          }
        })
        .then((res) => {
          //保存用户信息
          localStorage.setItem("userInfo", res.data.data);
          this.$router.push("relation");
        });
    },
    inputfocus() {
      var v = this.form.username;
      if (v == null || v == "") {
        // this.$refs.username.css("display", "block");
      } else {
        // this.$refs.username.css("display", "none");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 613px;
  height: 628px;
  background: #ffffff;
  border: 1px solid #e8eaec;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &-header {
    height: 32px;
    margin-top: 40px;
    display: flex;
    justify-content: center;

    img {
      height: 32px;
      width: 41px;
    }

    div {
      height: 32px;
      line-height: 32px;
      font-size: 22px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: left;
      color: #000000;
      margin-left: 12px;
    }
  }

  &-form {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    /deep/.input-wrapper {
      width: 400px;
    }

    /deep/.el-button {
      width: 400px;
    }
  }

  .login-other {
    height: 47px;
    width: 84px;
    font-size: 14px;
    color: #888888;
    margin: 0 auto;
    margin-top: 30px;
    box-sizing: border-box;

    .login-other-way {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      padding: 0 2px;

      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  .login-register {
    width: 400px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #ecf0f5;
    border-radius: 2px;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    margin-top: 26px;
  }
}
.send-code {
  display: flex;
}
.input-wrapper {
  width: 300px;
  height: 42px;
  margin-bottom: 20px;
  position: relative;
}

.input-wrapper input {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px 15px;
  outline: none;
  position: absolute;
  background-color: transparent;
  z-index: 1;
}

.input-wrapper label {
  width: 290px;
  height: 36px;
  line-height: 36px;
  padding: 3px 5px;
  font-family: simsun;
  font-size: 14px;
  color: #999;
  // **cursor: text;
  // position: absolute;
  // top: 0px;
  // left: 0px;**
}
</style>
